import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import ErrorState from '@ui/ErrorState';
import Flex from '@ui/Flex';

import classes from './index.module.scss';

interface MdxViewErrorProps {
  /**
   * URL to the page editor for the current page.
   */
  editUrl: string;
}

/**
 * Used to display an error state when an MDX page has an error when in view mode.
 */
export default function MdxViewError({ editUrl }: MdxViewErrorProps) {
  const bem = useClassy(classes, 'MdxViewError');
  return (
    <ErrorState
      className={bem('&')}
      message={
        <Flex align="center" gap="md" layout="col">
          <span className={bem('-description')}>
            This page will be hidden from your end users until the errors are fixed.
          </span>
          <Button size="sm" to={editUrl}>
            Fix this Page
          </Button>
        </Flex>
      }
      title="This Page Contains Invalid MDX"
    />
  );
}
