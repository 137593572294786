import type { RDMDOpts } from '@readme/iso';

import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import MdxViewError from '@routes/SuperHub/Layout/MdxViewError';

import RDMD, { TOC } from '@ui/RDMD';

interface DocBodyProps {
  dehydrated?: {
    body: string;
    toc: string;
  };
  docBody: string;
  editUrl: string;
  footer: React.ReactNode;
  hasMdxError: boolean;
  hideTOC: boolean;
  mdx: boolean;
  opts: RDMDOpts;
}

const DocBody = ({
  hideTOC,
  dehydrated,
  docBody,
  mdx,
  opts = {},
  footer,
  hasMdxError = false,
  editUrl,
}: DocBodyProps) => {
  const { isClient } = useEnvInfo();

  return (
    <div className="grid-container-fluid" id="content-container">
      <section className={`content-body grid-${hideTOC ? 100 : 75}`}>
        {hasMdxError && editUrl ? (
          <MdxViewError editUrl={editUrl} />
        ) : (
          <RDMD
            key={isClient ? window.location.pathname : null}
            body={docBody}
            className="rm-Markdown markdown-body ng-non-bindable"
            dehydrated={dehydrated?.body}
            mdx={mdx}
            opts={opts}
            style={{ marginLeft: 31 }}
          />
        )}
        {!!footer && footer}
      </section>
      {!hideTOC && (
        <section className="content-toc grid-25">
          <TOC body={docBody} mdx={mdx} opts={opts} />
        </section>
      )}
    </div>
  );
};

export default DocBody;
